/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ReplaySubject, filter, map } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {
	private readonly wsUrl = environment.endpoints.wsUrl;

	private readonly messages$ = new ReplaySubject(10);

	connect() {
		const subject = webSocket(this.wsUrl);

		subject.subscribe({
			next: msg => {
				console.log('message received: ', msg);
				this.messages$.next(msg);
			}, // Called whenever there is a message from the server.
			error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
			complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
		});
	}

	watchRawPartsUploadEvents() {
		return this.messages$.pipe(
			filter((msg: any) => 'rawPartsImport' in msg),
			map((msg: any) => msg.rawPartsImport)
		);
	}
	watchForScaEvents() {
		return this.messages$.pipe(
			filter((msg: any) => 'scaQueue' in msg),
			map((msg: any) => msg.scaQueue)
		);
	}

	watchForScaEventsForPart(partId: string) {
		return this.watchForScaEvents().pipe(filter((scaQueue: any) => scaQueue.quote_parts.includes(partId)));
	}
}
